.parent_div{
    background-color: var(--secondary-color);
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    align-items: center;
}
.footer_container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary-color);
    padding-bottom: 10px;
    @media screen and (max-width: 450px) {
        display: block;
    }
}

.footer_item{

    span{
        font-weight: 500;
        color: #FFD264;
    }
    p{
        color: white;
    }
}

.footer_item_copy{
    align-items: center;
    // background-color: aqua;
    p{
        font-weight: 200;
        font-size: 10px;
        text-align: center;
        color: #333;
    }
}

.footer_item_logo{
    width: 200px;
    height: 100px;
    // background-color: white;
    img{
        width: 200px;
        height: 100%;
    }

    @media screen and (max-width: 900px) {
        width: 100%;
        // background-color: #FFD264;
    }
}