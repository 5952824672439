.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  flex-direction: column; // added
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.reverse{
  flex-direction: row-reverse;
}

.app__profile-item {
  width: 100%;
  display: flex;
  // background-color: aqua;
  justify-content: flex-start;
  align-items: flex-start;
  // flex-direction: column; // commented
    margin: 2rem;
  .text-container{
    width: 100%;
    padding-right: 10%;
    padding-left: 10%;
  }
  .bold-text {
    font-size: large;
  }
  p{
    font-size: 1.50rem;
    text-align: justify;
  }
  img {
    width: 100%;
    height: 80%;
    object-fit: contain;
    border-radius: 15px;
    // object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 70%;
    margin: 2rem 4rem;
    img {
      height: 320px;
    }
  }

  @media screen and (max-width: 450px) {
    width: 80%;
    // margin: 2rem 4rem;
    flex-direction: column; // commented
    

    .bold-text {
      font-size: medium;
    }
    p{
      font-size: 1.10rem;
    }
  }
}
